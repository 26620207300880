import React from "react";
// import Loader from "react-loader-spinner";
import loader from "./../assets/GIF/JSOn/25.loading.json";
import LottieGIF from "LottieComponent";

const LoaderLoad = ({ height }) => {
  return (
    // <div
    // 	className='d-flex justify-content-center align-items-center'
    // 	style={{
    // 		height: height ? `100%` : `auto`,
    // 		position: "absolute",
    // 		width: "100%",
    // 		top: "0px",
    // 		left: "0px",
    // 		backgroundColor: "transparent",
    // 		zIndex: "111",
    // 	}}>
    // 	<Loader type='Rings' color='#E45682' height={100} width={100} />
    // </div>
    <div
      className="row m-0 justify-content-center align-items-center"
      style={{
        height: height ? `100%` : `auto`,
        position: "absolute",
        width: "100%",
        top: "0px",
        left: "0px",
        backgroundColor: "transparent",
        zIndex: "111",
      }}
    >
      <LottieGIF
        json={loader}
        loop={false}
        height="8rem"
        width="8rem"
      ></LottieGIF>
    </div>
  );
};

export default LoaderLoad;
