const loadGoogleMapsAPI = () => {
    if (!process.env.REACT_APP_GOOGLE_MAP_API_KEY) {
        console.error('Google Maps API key is missing.');
        return;
    }

    const existingScript = document.getElementById('googleMaps');
    if (!existingScript) {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}&libraries=places`;
        script.id = 'googleMaps';
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);

        script.onload = () => {
            console.log('Google Maps API loaded successfully');
        };

        script.onerror = () => {
            console.error('Failed to load the Google Maps API');
        };
    }
};

export default loadGoogleMapsAPI;
