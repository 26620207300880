import React, { memo } from "react";

// icon
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const LogOutModal = () => {
  const history = useHistory();

  const toggle = () => {
    const myModal = window.bootstrap.Modal.getOrCreateInstance(
      document.getElementById("logoutModal")
    );

    myModal.hide();
  };

  function logoutpage() {
    sessionStorage.removeItem("user__token");
    history.push("/login-link");
    window.location.reload();
  }

  return (
    <div className="modal fade" id="logoutModal" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered  modal-lg">
        <div className="modal-content">
          <div className="modal-body">
            <img
              id="gif"
              src="/assets/GIF/ Dialogue - Logout.gif"
              alt="LogOutIcon"
            />
            <h2 id="header">Oh no!</h2>
            <p id="sub__header">You're leaving... Are you sure?</p>

            <div className="d-flex justify-content-center align-items-center mb__3 w-100">
              <button
                type="cancel"
                className="defaultButton"
                style={{
                  marginRight: "2rem",
                  height: "40px",
                  width: "100%",
                  maxWidth: "150px",
                  minWidth: "auto",
                }}
                onClick={toggle}
              >
                Cancel
              </button>

              <button
                type="submit"
                className="appButton"
                style={{
                  height: "40px",
                  width: "100%",
                  maxWidth: "150px",
                  minWidth: "auto",
                }}
                onClick={logoutpage}
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(LogOutModal);
