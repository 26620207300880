import React, { lazy } from "react";
import { Link } from "react-router-dom";
import AuthHeader from "./AuthHeader";

//modal
import LogOutModal from "components/modals/Logout/LogOutModal";
import InviteModal from "components/modals/InviteModal";
const Logo = "/assets/logo.png";

const PublicNav = lazy(() => import("./PublicNav"));

const NavBar = ({ isServerDown }) => {
  const authRoute = sessionStorage.getItem("user__token");

  return (
    <>
      <nav className="navbar navbar-expand-lg fixed-top header">
        <div className="container-fluid px-4">
          <Link className="navbar-brand" to="/">
            <div className="d-flex justify-content-center align-items-center">
              <img id="logo" src={Logo} alt="logo" />
              <p id="brand__name">Parking Bud</p>
            </div>
          </Link>

          {authRoute && !isServerDown ? (
            <div className="auth__header">
              <AuthHeader />
            </div>
          ) : (
            <PublicNav />
          )}
        </div>
      </nav>
      {authRoute && <InviteModal />}

      <LogOutModal />
    </>
  );
};

export default NavBar;
