import React, { useEffect } from "react";
import "styles/style.css";
import IndexRoute from "routes/IndexRoute";
import ErrorBoundary from "components/Config/ErrorBoundary";
import loadGoogleMapsAPI from "./utils/loadGoogleMapsAPI"; 

function App() {
  useEffect(() => {
    // Load the Google Maps API when the app is loaded
    loadGoogleMapsAPI();
  }, []);

  return (
    <ErrorBoundary>
      <IndexRoute />
    </ErrorBoundary>
  );
}

export default App;
