import React, { memo } from "react";
import Lottie from "react-lottie";
import PropTypes from "prop-types";

const LottieFile = (props) => {
	const defaultOptions = {
		loop: props.loop || true,
		autoplay: props.autoplay || true,
		animationData: props.json,
	};

	return <Lottie options={defaultOptions} />;
};

LottieFile.prototype = {
	loop: PropTypes.bool,
	autoplay: PropTypes.bool,
	animationData: PropTypes.object,
};

export default memo(LottieFile);
