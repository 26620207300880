import React from "react";
import Lottie from "react-lottie";

export default function LottieGIF(props) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: props.json,
  };
  if (typeof props.json !== "object") return <></>;
  return (
    <div>
      <Lottie
        options={defaultOptions}
        height={props.height}
        width={props.width}
      />
    </div>
  );
}
