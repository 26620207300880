import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useRouteMatch, useHistory, useLocation } from "react-router-dom";
import axiosInstance from "./../../axios";
import { DATA_PROCESSOR } from "components/Tools";
import ProfilePopup from "./profilepopup";
import dotenv from "dotenv";
import { logoutSession } from "useable/supportFunctions";
import toast from "react-hot-toast";
const HomeIcon = "/assets/icons/home.svg";
const BellIcon = "/assets/icons/bell.svg";
const ParkingIcon = "/assets/icons/parking.svg";
const LeftCurve = "/assets/icons/leftSide.png";
const RightCurve = "/assets/icons/rightSide.png";
const emptyProfileIcon = "/assets/icons/Icon feather-user.svg";

dotenv.config({ path: "/server/.env" });

const AuthHeader = () => {
  const mobileNavRef = useRef(null);
  const history = useHistory();

  // toggle mobile navbar
  const [show, setShow] = useState(false);
  const [state, setState] = useState({
    profile_pic: null,
    email: null,
    id: null,
    first_name: null,
    last_name: null,
  });
  const [indicater, setindicator] = useState(0);
  const location = useLocation();

  // route match
  const homeRoute = {
    isExact:
      location.pathname.startsWith("/home") ||
      location.pathname.startsWith("/reservation"),
  };
  const bookingsRoute = { isExact: location.pathname.startsWith("/bookings") };
  const notificationRoute = useRouteMatch("/notifications");
  const profileRoute = useRouteMatch("/profile");
  const [logout, setLogout] = useState(false);
  const [profileImage, setProfileImage] = useState(
    state.profile_pic != null ? state.profile_pic : emptyProfileIcon
  );
  const LogoutToggle = () => {
    if (logout) {
      setLogout(false);
    } else {
      setLogout(true);
    }
  };

  const toggleMobileNav = useCallback((e) => {
    if (mobileNavRef.current && !mobileNavRef.current.contains(e.target)) {
      // !ref.current.contains(e.target) ===>  that ensures that the clicked div is toogle div or outsider div
      setShow(false);
    }
  }, []);

  const handleHamBurger = useCallback((e) => {
    e.preventDefault();

    setShow((prevState) => !prevState);
  }, []);

  // toggle mobile navbar
  useEffect(() => {
    document.addEventListener("click", toggleMobileNav, true);

    return () => {
      document.addEventListener("click", toggleMobileNav, true);
    };
  }, [toggleMobileNav]);

  // to hide navbar dropdown every time route change for mobile
  useEffect(() => {
    return history.listen((location) => {
      setShow(false);
    });
  }, [history]);

  async function makerequest() {
    let data = "";
    await axiosInstance
      .get("/consumer_dash/edit_consumer_profile")
      .then((response) => {
        //let formData = new FormData();
        //console.log(this.confirmPassword);
        // console.log("data", response.data.response.user)
        data = response.data.response.user;
        DATA_PROCESSOR(data, state, setState);
        setProfileImage(
          data.profile_pic != null ? data.profile_pic : emptyProfileIcon
        );
      })
      .catch((err) => {
        if (err.response?.status === 401) {
          logoutSession();
        } else {
          toast.error("Something Went Wrong.");
        }
      });
  }

  useEffect(() => {
    makerequest();
  }, []);

  useEffect(() => {
    if (!state.id) {
      return;
    }

    let io = new WebSocket(
      process.env.REACT_APP_BACKEND_DEVELOPMENT_URL_SOCKET +
        "/ws/consumer_simple_notification/" +
        state.id +
        "/"
    );

    io.onopen = (e) => {};
    io.onmessage = (e) => {
      let data = JSON.parse(e.data);
      setindicator(data.added_notifications);
      if (data.type && data.type === "new_notification") {
      }
    };
  }, []);
  const handleImageError = () => {
    setProfileImage(emptyProfileIcon);
  };
  return (
    <>
      <div className="auth">
        <div className="menu">
          <ul className={"menu__items"}>
            <li
              id="home"
              className={
                homeRoute?.isExact ? "menu__lists active" : "menu__lists "
              }
              onClick={() => history.push("/home")}
            >
              <Link to="/home">
                <p className="item__header">Home</p>
                <div className="image__wrapper">
                  <div className="wrap">
                    <img id="left__curve" src={LeftCurve} alt="left-curve" />
                    <img className="logo__icon" src={HomeIcon} alt="icons" />
                    <img id="right__curve" src={RightCurve} alt="right-curve" />
                  </div>
                </div>
              </Link>
            </li>

            <li
              id="parking"
              className={
                bookingsRoute?.isExact ? "menu__lists active" : "menu__lists "
              }
              onClick={() => history.push("/bookings")}
            >
              <Link to="/bookings">
                <p className="item__header">Bookings</p>
                <div className="image__wrapper">
                  <div className="wrap">
                    <img id="left__curve" src={LeftCurve} alt="left-curve" />
                    <img className="logo__icon" src={ParkingIcon} alt="icons" />
                    <img id="right__curve" src={RightCurve} alt="right-curve" />
                  </div>
                </div>
              </Link>
            </li>

            <li
              id="notification"
              className={
                notificationRoute?.isExact
                  ? "menu__lists active"
                  : "menu__lists "
              }
              onClick={() => history.push("/notifications")}
            >
              <Link to="/notifications">
                <p className="item__header">Notification</p>
                {indicater != 0 ? <span className="bi-dot dott"></span> : null}
                <div className="image__wrapper">
                  <div className="wrap">
                    <img id="left__curve" src={LeftCurve} alt="left-curve" />
                    <img className="logo__icon" src={BellIcon} alt="icons" />
                    <img id="right__curve" src={RightCurve} alt="right-curve" />
                  </div>
                </div>
              </Link>
            </li>

            <li className="nav-item menu__lists d-flez flex-row">
              <Link
                className="nav-link toggle__profile  dropdown-toggle "
                to="#"
                id="navbarDropdownMenuLink"
                aria-expanded="false"
                data-bs-toggle="dropdown"
                role="button"
                style={{ display: "block" }}
              >
                <img
                  className="profile"
                  src={profileImage}
                  alt="icons"
                  onError={handleImageError}
                  style={{ filter: "none" }}
                />
              </Link>

              <div className="dropdown-menu " aria-labelledby="navbarDropdown">
                <ProfilePopup
                  isOpen={true}
                  profile_pic={profileImage}
                  name={state.first_name + " " + state.last_name}
                  email={state.email}
                  logoutpage={LogoutToggle}
                />
              </div>
            </li>
          </ul>
        </div>

        <div
          ref={mobileNavRef}
          className={show ? "mobile__version show" : "mobile__version"}
        >
          <button className="hamburger" onClick={handleHamBurger}>
            <span></span>
            <span></span>
            <span></span>
          </button>

          <div className="__body">
            <ul className="links__list">
              <li
                className={
                  homeRoute?.isExact ? "links__items active" : "links__items"
                }
              >
                <span className="icon">
                  <img src={HomeIcon} alt="icons" />
                </span>
                <Link className="link" to="/home">
                  <p>Home</p>
                </Link>
              </li>

              <li
                className={
                  bookingsRoute?.isExact
                    ? "links__items active"
                    : "links__items"
                }
              >
                <span className="icon">
                  <img src={ParkingIcon} alt="icons" />
                </span>
                <Link className="link" to="/bookings">
                  <p>Bookings</p>
                </Link>
              </li>

              <li
                className={
                  notificationRoute?.isExact
                    ? "links__items active"
                    : "links__items"
                }
              >
                <span className="icon ">
                  <img src={BellIcon} alt="icons" />
                </span>
                <Link className="link" to="/home/notifications">
                  <p>Notification</p>
                </Link>
              </li>
              <li
                className={
                  profileRoute?.isExact ? "links__items active" : "links__items"
                }
              >
                <span className="icon ">
                  <img
                    alt="icons"
                    src={profileImage}
                    onError={handleImageError}
                  />
                </span>
                <Link className="link" to="/profile">
                  <p>Profile</p>
                </Link>
              </li>
              <li
                className={
                  notificationRoute?.isExact
                    ? "mt-5 p-2 links__items active"
                    : " mt-5 p-2 links__items"
                }
              >
                <button
                  data-bs-toggle="modal"
                  data-bs-target="#logoutModal"
                  className="text-danger bg-transparent border-0"
                >
                  Log out
                </button>
              </li>
            </ul>
          </div>
        </div>
        {/* <img className='bubble' src={DropDownIcon} alt='bubble' /> */}
      </div>
    </>
  );
};

export default AuthHeader;
