import moment from "moment";

export const ERROR__PROCESSOR = (errors, errMsg, setErrMsg) => {
  let temp = errMsg;
  // console.log(errors)
  for (var err in errors) {
    if (err in errMsg) {
      temp = { ...temp, [err]: errors[err][0] };
    }
  }
  setErrMsg(temp);
};
export function convertTimeFormat(inputTime) {
  const m = moment(inputTime, "YYYY-MM-DDTHH:mm:ssZ");

  // Format the time as "MMM Do[th], YYYY h:mm A" (e.g., Jul 18th, 2024 5:24 AM)
  const formattedTime = m.format("MMM Do, YYYY h:mm A");

  return formattedTime;
}

export function timeLeftUntil(endTime) {
  const date = new Date();
  const utcDateString = date.toISOString();
  const now = moment(utcDateString);
  const end = moment(endTime);
  const diff = end.diff(now);

  // Calculate duration components
  const duration = moment.duration(diff);
  const days = Math.floor(duration.asDays());
  const hours = duration.hours();
  const minutes = duration.minutes();

  // Construct the output string
  let output = `${days} d ${hours} h ${minutes} m`;

  return output;
}
export function timeLeftUntilPass(endTime) {
  const date = new Date();
  const utcDateString = date.toISOString();
  const now = moment(utcDateString);
  const end = moment(endTime);
  const diff = end.diff(now);

  // If the time difference is negative, return "00:00:00"
  if (diff <= 0) {
    return "00d 00h 00m";
  }

  // Calculate duration components
  const duration = moment.duration(diff);
  const days = Math.floor(duration.asDays());
  const hours = duration.hours();
  const minutes = duration.minutes();

  // Format with leading zeros
  const formattedDays = String(days).padStart(2, "0");
  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");

  // Construct the output string
  let output = `${formattedDays}d ${formattedHours}h ${formattedMinutes}m`;

  return output;
}

export const DATA_PROCESSOR = (data, state, setState) => {
  let temp = state;
  for (const key in data) {
    if (key in state) {
      temp = { ...temp, [key]: data[key] };
    }
  }
  setState(temp);
};

export const DATETOWORDS = (date) => {
  var dob = new Date(date);
  var dobArr = dob.toDateString().split(" ");
  return dobArr[1] + " " + dobArr[2] + ", " + dobArr[3];
};

export const TIMETOWORDS = (time) => {
  const t = time.split(":");
  return t[0] + "h " + t[1] + "m";
};

export const GETTIMEFROMDATE = (date) => {
  var dob = new Date(date);
  return dob.toLocaleString("en-US", { hour: "numeric", hour12: true });
};

export const DateConvert = (date) => {
  var dob = new Date(date);
  return dob;
};

export const TIMEDIFFERENCECALC = (date1, date2) => {
  // console.log("----",date1, date2)
  let dateFuture = new Date(date2);
  let dateNow = new Date(date1);

  if (dateFuture < dateNow) {
    return "0 h 0 m";
  }

  let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

  // calculate days
  const days = Math.floor(diffInMilliSeconds / 86400);
  diffInMilliSeconds -= days * 86400;

  // calculate hours
  const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
  diffInMilliSeconds -= hours * 3600;

  // calculate minutes
  const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
  diffInMilliSeconds -= minutes * 60;

  let difference = "";
  if (days > 0) {
    difference += days === 1 ? `${days} day ` : `${days} days `;
  } else {
    difference += hours === 0 || hours === 1 ? `${hours} h ` : `${hours} h `;

    difference +=
      minutes === 0 || hours === 1 ? `${minutes} m` : `${minutes} m`;
  }

  return difference;
};

export const CONVERTMINUTESTOHHMM = (minutes) => {
  var MINUTES = minutes; //some integer

  var m = MINUTES % 60;

  var h = (MINUTES - m) / 60;

  return h.toString() + "h " + (m < 10 ? "0" : "") + m.toString() + "m";
};

const tConvert = (time) => {
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(""); // return adjusted time or original string
};

export const CONVERTHOURSOFOPERATION = (str) => {
  let temp = str.split(",");
  for (var i in temp) {
    if (temp[i] != "") {
      //console.log("i--",temp[i])
      let x = temp[i].split("-");
      temp[i] = tConvert(x[0]) + " - " + tConvert(x[1]) + " ";
      // console.log("i----", temp[i])
    }
  }
  return temp;
};
export function convertPassFormate(dateStr) {
  // Parse the input date string as a Date object
  const date = new Date(dateStr);

  // Define options for formatting the date
  const options = {
    weekday: "short", // 'Wed'
    day: "2-digit", // '30'
    month: "short", // 'Dec'
    hour: "numeric", // '12'
    minute: "2-digit", // '30'
    hour12: true, // 'PM'
  };

  // Format the date in the local timezone
  const formattedDate = date.toLocaleDateString(undefined, options);

  // Combine date and time
  return `${formattedDate}`;
}
