import "styles/style.css";
import IndexRoute from "routes/IndexRoute";
import ErrorBoundary from "components/Config/ErrorBoundary";

function App() {
  return (
    <ErrorBoundary>
      <IndexRoute />
    </ErrorBoundary>
  );
}

export default App;
