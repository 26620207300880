import React from "react";
import LottieGIF from "LottieComponent";
import loader from "./../../assets/GIF/JSOn/24. no internet.json";

function Maintenance() {
  return (
    <div>
      <div className="login">
        <div className="container" style={{ height: "21rem" }}>
          <div className="row m-0 justify-content-center align-items-center">
            <LottieGIF
              json={loader}
              loop={true}
              height="8rem"
              width="8rem"
            ></LottieGIF>
          </div>
          <h2 id="header">We'll be Right Back!</h2>
          <p id="sub__header" style={{ textAlign: "center" }}>
            We’re currently doing some updates to make your experience even
            better.
            <br /> Please bear with us, and we’ll be back online soon!
          </p>
          <p id="sub__header" style={{ fontStyle: "italic", color: "#666" }}>
            Thank you for your patience!
          </p>
        </div>
      </div>
    </div>
  );
}

export default Maintenance;
