import { useEffect, useState } from "react";
import toast from "react-hot-toast";

function useServerStatus(serverUrl) {
  const [status, setStatus] = useState({
    isOnline: navigator.onLine,
    isServerAvailable: false,
    websiteDown: false,
    appDown: false,
    isLoading: true,
  });
  const [isMessageViewed, setIsMessageViewed] = useState(false);

  const checkInternetConnection = () => {
    const online = navigator.onLine;
    setStatus((prev) => ({ ...prev, isOnline: online }));
  };

  const checkServerStatus = async (retryCount = 3) => {
    setStatus((prev) => ({ ...prev, isLoading: true }));
    try {
      const response = await fetch(serverUrl + "health", { 
        method: "GET",
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
        }
      });
      const data = await response.json();
      if (response.ok) {
        setStatus((prev) => ({
          ...prev,
          isServerAvailable: true,
          websiteDown: data.response.websiteDown,
          appDown: data.response.appDown,
          isLoading: false,
        }));
        setIsMessageViewed(false);
      } else {
        if (!isMessageViewed) {
          toast.error("Temporary server issue. Some features may be limited.");
          setIsMessageViewed(true);
        }
        setStatus((prev) => ({
          ...prev,
          isServerAvailable: false,
          isLoading: false,
        }));
      }
    } catch (error) {
      if (
        error.message === "Failed to fetch" ||
        error.code === "ERR_NETWORK_CHANGED" ||
        error instanceof TypeError ||
        error.message.includes('CORS')
      ) {
        if (retryCount > 0) {
          setTimeout(() => checkServerStatus(retryCount - 1), 2000);
        } else {
          if (!isMessageViewed) {
            toast.error(
              "Unable to connect to server. Please check if the server is running."
            );
            setIsMessageViewed(true);
          }
          setStatus((prev) => ({
            ...prev,
            isServerAvailable: false,
            isLoading: false,
          }));
        }
      }
    }
  };

  useEffect(() => {
    checkServerStatus();

    window.addEventListener("online", checkInternetConnection);
    window.addEventListener("offline", checkInternetConnection);

    return () => {
      window.removeEventListener("online", checkInternetConnection);
      window.removeEventListener("offline", checkInternetConnection);
    };
  }, [serverUrl]);

  useEffect(() => {
    if (!status.isOnline) {
      toast.error("You are offline. Check your internet connection.", {
        duration: Infinity,
      });
    } else {
      toast.dismiss();
      checkServerStatus();
    }
  }, [status.isOnline]);

  return status;
}

export default useServerStatus;
