import React, { Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import LoaderLoad from "components/Loader";
import NavBar from "components/NavBar/NavBar";
import NoteState from "contaxt/NoteState";
import { Toaster } from "react-hot-toast";
import useServerStatus from "components/Config/ConnectionStatus";
import Maintenance from "components/Config/Maintenance";

const RegisterPage = lazy(() => import("pages/Register/Register"));
const EmailLoginPage = lazy(() =>
  import("pages/LoginWithEmail/EmailLoginPage")
);
const ForgotPasswordOption = lazy(() =>
  import("pages/ForgotPasswordOption/ForgotPasswordOption")
);
const LoginPage = lazy(() => import("pages/LoginPage/LoginPage"));
const ForgotEmail = lazy(() => import("pages/ForgotEmail/ForgotEmail"));
const ForgotPassPhone = lazy(() => import("pages/ForgotPhone/ForgotPassPhone"));
const ResetPasswordPage = lazy(() =>
  import("pages/ResetPassword/ResetPasswordPage")
);
const VerificationPage = lazy(() =>
  import("pages/VerficationPage/VerificationPage")
);
const LandingPage = lazy(() => import("pages/LandingPage/LandingPage"));
const RentYourSpace = lazy(() => import("pages/LandingPage/RentYourParking"));
const ContactUs = lazy(() => import("pages/LandingPage/ContactUs"));
const AboutUs = lazy(() => import("pages/LandingPage/AboutUs"));
const TermsConditions = lazy(() =>
  import("pages/LandingPage/TermsAndConditions")
);

const AuthRoute = lazy(() => import("./AuthRoute"));

const IndexRoute = () => {
  const isAuthenticated = sessionStorage.getItem("user__token");

  const serverStatus = useServerStatus(
    process.env.REACT_APP_BACKEND_DEVELOPMENT_URL
  );

  const { isServerAvailable, websiteDown, appDown, isLoading } = serverStatus;

  if (isLoading) return <></>;
  if (!isServerAvailable) {
    return (
      <Router>
        <Suspense fallback={<LoaderLoad height />}>
          <NavBar isServerDown={true} />
          <Switch>
            <Route exact path="/" component={LandingPage} />
            <Route exact path="/rent-your-parking" component={RentYourSpace} />
            <Route exact path="/about-us" component={AboutUs} />
            <Route exact path="/terms-conditions" component={TermsConditions} />
            <Route path="*" component={() => <Redirect to="/" />} />
          </Switch>
        </Suspense>
        <CustomToaster />
      </Router>
    );
  }

  if (websiteDown) {
    return (
      <Router>
        <Switch>
          <Route path="*" component={() => <Maintenance />} />
        </Switch>
        <CustomToaster />
      </Router>
    );
  }

  return (
    <>
      <Router>
        <Suspense fallback={<LoaderLoad height />}>
          <NavBar />
          <div className="container__body">
            {isAuthenticated ? (
              <>
                {appDown ? (
                  <Switch>
                    <Route path="*" component={() => <Maintenance />} />
                  </Switch>
                ) : (
                  <NoteState>
                    <AuthRoute />
                  </NoteState>
                )}
              </>
            ) : (
              <Switch>
                <Route exact path="/login-link" component={LoginPage} />
                <Route exact path="/" component={LandingPage} />
                <Route
                  exact
                  path="/rent-your-parking"
                  component={RentYourSpace}
                />
                <Route exact path="/contact-us" component={ContactUs} />
                <Route exact path="/about-us" component={AboutUs} />
                <Route
                  exact
                  path="/terms-conditions"
                  component={TermsConditions}
                />
                <Route path="/register" component={RegisterPage} />
                <Route path="/login" component={EmailLoginPage} />
                <Route
                  path="/forgot-options"
                  component={ForgotPasswordOption}
                />
                <Route path="/forgot-email" component={ForgotEmail} />
                <Route path="/forgot-phone" component={ForgotPassPhone} />
                <Route path="/verify-email" component={VerificationPage} />
                <Route path="/verify-phone" component={VerificationPage} />
                <Route path="/reset-password" component={ResetPasswordPage} />

                <Route path="*" component={() => <Redirect to="/" />} />
              </Switch>
            )}
          </div>
        </Suspense>
      </Router>
      <CustomToaster />
    </>
  );
};

export default IndexRoute;

const CustomToaster = () => {
  return (
    <Toaster
      position="top-center"
      reverseOrder={false}
      toastOptions={{
        duration: 5000,
        style: {
          border: "0.5px solid rgba(192, 192, 192, 0.4)",
          fontWeight: 800,
          fontSize: "14px",
          background: "rgba(0, 0, 0, 0.8)",
          color: "white",
        },
      }}
    />
  );
};
