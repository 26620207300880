import React, { memo, useCallback, useState, useEffect } from "react";
import { handleCopyText } from "config/app.config";
import axiosInstance from "./../../axios";
// Icons
import InviteIcon from "../../assets/GIF/Profile – Invite Friends.gif";
import InviteFriendJSON from "../../assets/GIF/JSOn/26.ReferAFriend.json";

// component
import LottieFile from "components/LottieFile";
import toast from "react-hot-toast";

const InviteModal = () => {
  const [copied, setCopied] = useState(false);
  const [referral, setreferral] = useState({});

  const handelCopy = useCallback((value) => {
    handleCopyText(
      `${process.env.REACT_APP_USER_URL}/register?referral_code=${value}`
    );
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 500);
  }, []);

  useEffect(async () => {
    await axiosInstance
      .post("consumer_dash/reffrel_code")
      .then((response) => {
        if (response) {
          // console.log(response.data.response[0])
          setreferral(response.data.response[0]);
        }
      })
      .catch((err) => {
        toast.error("Soemthing went wrong.");
        // console.log("----", err.response)
      });
  }, []);

  return (
    <div
      className="modal fade invite__friend"
      id="inviteModal"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered  modal-lg">
        <div className="modal-content">
          <div className="modal-body">
            <div style={{ width: "200px" }}>
              <LottieFile json={InviteFriendJSON} />
            </div>
            <h2 id="header">Invite a Friend</h2>
            <p
              id="sub__header"
              className="mt-4 text-center"
              style={{ maxWidth: "400px" }}
            >
              Love Parking Bud? Sharing is caring! Invite your friends using
              this code and get a discount of up to 20%.
            </p>

            <p
              className="coupon__code"
              data-bs-toggle="modal"
              data-bs-target="#inviteModal"
              style={{ cursor: "pointer" }}
              onClick={() => handelCopy(referral.referral_code)}
            >
              <span>{referral.referral_code}</span>
              <i className="bi bi-files"></i>
              <span className="tooltip__text ml-1">
                {copied ? "Copied!" : "Copy to clipboard"}
              </span>
            </p>

            {/* <div className="d-flex justify-content-center align-items-center mb-2">
              <button
                type="submit"
                className="appButton"
                // onClick={handleSubmit}
              >
                Send Invitation
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(InviteModal);
