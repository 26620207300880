import axios from "axios";
import dotenv from "dotenv";
dotenv.config({ path: "/server/.env" });

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_DEVELOPMENT_URL,
  headers: {
    accept: "*/*",
    Authorization: sessionStorage.getItem("user__token") || "",
  },
});

export default axiosInstance;
