import toast from "react-hot-toast";

export function logoutSession() {
  sessionStorage.clear();
  window.location.reload();
  toast.error("Session Timeout!");
}
export function getTimeDifference(start, end) {
  const startDate = new Date(start);
  const endDate = new Date(end);
  const diffMs = endDate - startDate;

  const days = Math.floor(diffMs / (1000 * 60 * 60 * 24));
  const hours = Math.floor((diffMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));

  let result = "";
  if (days > 0) {
    result += `${days}d `;
  }
  if (hours > 0 || days > 0) {
    result += `${hours}h `;
  }
  result += `${minutes}m`;

  return result.trim();
}
export function getDecimalHoursDifference(start, end) {
  const startDate = new Date(start);
  const endDate = new Date(end);
  const diffMs = endDate - startDate;

  const hours = diffMs / (1000 * 60 * 60); // Convert milliseconds to hours
  return hours.toFixed(3);
}
export function isTimeGapValid(startTime, endTime) {
  // Convert the timestamp strings to Date objects
  const start = new Date(startTime);
  const end = new Date(endTime);

  // Get the time difference in milliseconds
  const timeDifference = end - start;

  // Convert the time difference from milliseconds to minutes
  const timeDifferenceInMinutes = timeDifference / (1000 * 60);

  // Check if the time gap is greater than or equal to 30 minutes
  return timeDifferenceInMinutes >= 30;
}
export const getCurrentTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone; // Get the IANA time zone string
};
export function formatNumberToTwoDecimal(amount) {
  const parsedAmount = parseFloat(amount);

  if (isNaN(parsedAmount) || parsedAmount < 0) {
    return 0;
  }

  return parsedAmount.toFixed(2);
}
